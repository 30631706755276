.qr {
  &__inner {
    display: block;
    width: 241px;

    border: 1px solid #eef3f9;

    padding: 19px;
    margin: 24px auto;
  }

  // .qr__input-wrapper

  &__input-wrapper {
    display: flex;
    position: relative;
  }

  // .qr__input

  &__input {
    max-width: 400px;
    width: 100%;
    border: 1px solid rgb(238, 243, 249);
    color: #252525;

    margin-bottom: 25px;
    padding: 8px 33px 8px 12px;

    border-radius: 6px;

    &::placeholder {
      color: rgb(184, 184, 184);
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
    }

    &-img__wrapper {
      position: absolute;
      top: 7px;
      right: 8px;
    }
  }

  // .qr__btn

  &__btn {
    width: 100%;
    background-color: #4a90e2;
    color: #fff;
    padding: 12px 0;
    border-radius: 8px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 8px;

    font-weight: 500;
    font-size: 13px;
  }
}

.ant-modal-footer {
  display: none !important;
}

.qr__input-img__wrapper {
  cursor: pointer;
}
