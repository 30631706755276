.characteristics_container {
    background: white;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 6px;
}

.characteristics_valueContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.characteristics_title {
    color: #757A80;
}

.characteristics_value {
    color: #B8B8B8;
}

.characteristics_color {
    padding: 4px 29px;
    border-radius: 24px;
    color: #FFFFFF;
}