.actionButton_container {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    width: 100%;
}

.actionButton_titleContainer {
    white-space: nowrap;
}