/*Main text style*/
.mainText {
    color: #252525;
}

.blueText {
    color: #4A90E2;
}

.lightDarkText {
    color: #757A80;
}
