.action-btn {
  max-width: 178px;
  width: 100%;
  max-height: 36px;

  padding: 9px 5px;
  border-radius: 6px;
  background-color: #4a90e2;
  color: #fff;
  font-weight: 500;
  font-size: 13px;

  margin-left: auto;
}

.action-btn:disabled {
  background-color: #b8b8b8;
  cursor: no-drop;
}
