@media (max-width: 768px) {
  .tableCard_firstRow {
    display: flex;
    flex-direction: column;
  }
  .tableCard_actionMenu {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 375px) {
  .tableCard_secondRowButtons {
    display: flex;
    flex-direction: column;
  }
}

.item_active {
  background-color: #4d9ff8;
  color: #fff;
}
