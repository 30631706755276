.tableBlock {
  width: 100%;
  overflow-x: scroll;
}

.tableBlock::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

.tableBlock::-webkit-scrollbar-track {
  background: white;
}

.tableBlock::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 20px;
}

.customTable_header {
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  background: #f1f5fb;
  padding: 15px;
  width: 100%;
  display: flex;
}

.customTable_header__item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.customTable_header__item:nth-child(1) {
  margin-right: 8px;
}

.customTable_header__item:nth-child(3) {
  margin-right: 18px;
}


.customTable_header__item img {
  cursor: pointer;
  margin-left: 8px;
}

.customTable_body {
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  background: #ffffff;
  margin-top: 16px;
}

.customTable_body__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
  font-weight: 400;
  cursor: pointer;
}

.customTable_body__row_filled {
  background: #f7f9fe;
}

.customTable_body__row_active_filled {
  background: #4a90e2;
}

.activeRow {
  background: #ffebcd;
}

.customTable_body__row p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
}

.customTable_body__notFound {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 50px;
}

.customTable_body__row__actionBlock {
}

.customTable_body__item-status {
  display: flex;
  justify-content: center;
  padding: 4px 26px !important;
  border-radius: 24px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #ffffff;
  max-width: 120px;
}

.customTable_body__item-color {
  display: block;
  min-width: 24px;
  min-height: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.add-model-btn {
  background-color: transparent;
  padding: 4px 10px;
  color: #4a90e2;
  font-weight: 500;
  border-radius: 5px;
  border: 1px solid #4a90e2;
}
