.modelWindow_container {
  position: relative;
  flex: 1;
  background: white;
  /* border-radius: 6px 6px 0 0; */
  padding: 1rem;
  margin-top: 0.1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  z-index: 50;
  max-width: 29%;
}

.modelWindow_taskMobileWindow {
  overflow-y: scroll;
  height: 100%;
}

.modelWindow_taskContainer {
  display: flex;
  justify-content: space-between;
}

.modelWindow_closingImgContainer {
  width: 12px;
  height: 12px;
  position: relative;
  cursor: pointer;
}

.modelWindow_closingImg {
  filter: brightness(0%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modelWindow_imageContainer {
  width: 100%;
  /* height: 280px; */
  position: relative;
  border: 1px solid #edf1f7;
  border-radius: 6px;
  padding: 1rem;
}

.modelWindow_img {
  width: 100%;
  min-height: 250px;
  height: 250px;
  object-fit: contain;
  margin-bottom: 20px !important;
}

.modelWindow_img-noHistory {
  height: 226px;
}

.modelWindow_actionMenu {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.modelWindow_actionButtons {
  display: flex;
  gap: 1rem;
}

.modelWindow_noPrinterContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.nav {
  display: flex;
  padding: 0;
  margin: 24px 0 10px;

  .nav-item {
    list-style-type: none;
    padding: 0 16px 12px;
    cursor: pointer;

    &.active {
      border-bottom: 2px solid #4a90e2;
    }
  }
}

.nav-content {
  display: flex;
  flex-direction: column;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.textarea {
  width: 100%;
  height: 84px;
  margin-top: 24px;
  border-radius: 4px;
  resize: none;
  margin-bottom: 24px;
  border: none;
  background-color: white;

  &:hover {
    border: none !important;
  }

  &:focus {
    outline: none;
    border: none;
  }
}

.textarea::placeholder {
  color: #000;
}

.noHistory {
  margin-top: 25px;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #757a80;
}

.pointer {
  cursor: pointer;
}

.ant-carousel {
  width: 100%;
  align-self: center;
}

.arrow_wrapper {
  position: absolute;
  left: 0;
  top: 25%;
  z-index: 1000;
  width: 100%;
}

.arrow {
  position: absolute;
  width: 40px;
  height: 40px;

  border-radius: 100%;
  padding: 5px;

  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 1000;
}

.left {
  left: 10px;
}

.right {
  right: 10px;
}

@media (max-width: 600px) {
  .modelWindow_container {
    max-width: none;
  }
}
