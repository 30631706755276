.input {
  padding: 9px 12px 9px 40px;
  height: 36px;
}

.input_wrapper {
  position: relative;
  margin-top: 24px;
}

.input_wrapper::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 12px;
  width: 20px;
  height: 20px;
  background-image: url("../../../assets/images/icons/search.svg");
  background-repeat: no-repeat;
  z-index: 9;
}

.radio.checked {
  background-color: #4a90e2;
}

.radio {
  width: 22px;
  height: 22px;
  box-sizing: border-box;
  border: 1.5px solid rgba(0, 0, 0, 0.3);
  border-radius: 100%;
  cursor: pointer;
}

.project_list {
  max-height: 302px;
  height: 100%;
  overflow-y: scroll;
  border: 1px solid rgb(238, 243, 249);
  border-radius: 6px;
  padding: 4px 8px;
  margin-top: 8px;
}

.project_list::-webkit-scrollbar {
  width: 3px;
}

.project_list::-webkit-scrollbar-track {
  background: #c4c4c4;
}

.project_list::-webkit-scrollbar-thumb {
  background-color: rgb(77, 159, 248); /* Цвет бегунка */
  border-radius: 20px; /* Округление бегунка */
}

.project_list_item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 12px 8px;
}

.check {
  padding: 0px 0px 5px 4px;
}

.not-found {
  color: #242020;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  display: block;
}

.flex_container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-popover-disabled-compatible-wrapper{
  width: 100%;
}