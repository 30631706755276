.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 450px;
}

.input {
  padding: 0.5rem;
  background: white;
  border: 1px solid #eef3f9;
  border-radius: 6px;
}

.input.error {
  border: 1px solid red;
}

.input.error::placeholder {
  color: red;
}

.color {
  display: flex;
  gap: 20px;

  & > div {
    width: 100% !important;
  }
}

.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.color {
  display: inline-flex;
  margin-left: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.ant-modal-footer {
  display: flex !important;
}

.flex_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.unique_text {
  font-size: 13px;
  line-height: 18px;
  color: rgba(229, 9, 20, 1);
}
