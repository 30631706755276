.login-wrapper {
  transform: translateY(-50px);
  display: flex;
  flex-direction: column;
  gap: 24px 0;
  max-width: 343px;
  width: 100%;
  align-items: center;
}

.login-input-wrapper {
  position: relative;
  width: 100%;

  transition: all 0.3s;
}

.login-input-wrapper-placeholder {
  position: absolute;
  top: 14px;
  bottom: 12px;
  left: 12px;
  z-index: 2;
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: -2%;

  pointer-events: none;

  transition: all 0.3s;
}

.login-input-wrapper.active .login-input-wrapper-placeholder {
  font-size: 10px;
  top: 6px;
  bottom: 26px;
}

.login-input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  backdrop-filter: blur(27.18px);
  background: rgb(255, 255, 255);
  padding: 14px 12px 12px 12px;
  width: 100%;

  font-size: 15px;
  line-height: 120%;
  letter-spacing: -2%;
}

.login-input.error {
  border: 1px solid rgb(229, 9, 20);
}

.login-input:hover {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.login-input:focus {
  outline: 1px solid rgb(74, 144, 226) !important;
}

.login-title {
  color: rgb(37, 37, 37);
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
}

.login-error-message {
  color: rgb(229, 9, 20);
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
}

.simpleButton_container:disabled {
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid transparent;
  cursor: no-drop;
}

.simpleButton_container:disabled {
  background: rgba(0, 0, 0, 0.1);
}

.simpleButton_container:disabled .simpleButton_text {
  color: rgba(0, 0, 0, 0.2);
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
}

.login-logo {
  position: absolute;
  top: 40px;
  width: 130px;
  height: 49px;
}
