.relative {
  position: relative;
}

.messageBox {
  position: absolute;
  top: 0;
  right: 0;
  // .messageBox__img

  max-width: 500px;

  &__top {
    display: flex;
    align-items: center;
    gap: 0 10px;
  }

  &__text {
    font-weight: 500;
    font-size: 15px;
  }

  min-width: 500px;
  width: fit-content;
  background-color: #fff;
  box-shadow: 0 0 3px rgba($color: #000000, $alpha: 0.3);
  padding: 10px;
  border-radius: 15px;

  &__img {
  }

  // .messageBox__footer

  &__footer {
    display: flex;
    width: 100%;
    justify-content: end;
    gap: 0 10px;
  }

  &__list {
    margin: 0;
    padding: 0;
    margin-top: 5px;
    overflow-y: scroll;
    max-height: 80px;
    margin-bottom: 10px;
  }

  &__list::-webkit-scrollbar {
    width: 3px;
  }

  &__list::-webkit-scrollbar-track {
    background: #dfdfdf;
  }

  &__list::-webkit-scrollbar-thumb {
    background-color: rgb(61, 152, 248);
  }

  &__list-item {
    margin-bottom: 5px;
  }
}
.action-btn {
  max-width: 178px;
  width: 100%;
  max-height: 36px;

  padding: 9px 5px;
  border-radius: 6px;
  background-color: #4a90e2;
  color: #fff;
  font-weight: 500;
  font-size: 13px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: auto;
}

.action-btn:disabled {
  background-color: #b8b8b8;
  cursor: no-drop;
}
