.customModal {
    width: fit-content !important;
    min-width: 220px !important;
    max-width: 80%;
    position: relative;
}

.customModal .ant-modal-content {
    padding: 24px;
    background: #FFFFFF;
    border-radius: 8px;
}

.customModal .ant-modal-content .ant-modal-footer {
    display: none;
}

.customModal .ant-modal-content .ant-modal-body {
    padding: 0;
}

.customModal_closingButtonContainer {
    position: absolute;
    top: 10px;
    right: -30px;
    cursor: pointer;
    width: 18px;
    height: 18px;
}