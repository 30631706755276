body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

:where(.css-dev-only-do-not-override-mxhywb).ant-carousel .slick-prev::before {
  content: "<";
}

.default_input_style {
  padding: 0.5rem 70px 0.5rem 0.5rem;
  background: white;
  border: 1px solid #eef3f9;
  border-radius: 6px;
  margin-bottom: 20px;
  width: 100%;
}

.simpleButton_container {
  width: 100%;
  background: #4a90e2;
  padding: 1rem 0rem;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
