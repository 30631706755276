.counter_wrapper {
  position: relative;
}

.input_length_counter {
  position: absolute;
  right: 19px;
  top: 10px;
  font-size: 13px;
  color: #919191;
}
