@media (max-width: 1024px) {
    .drawerMenu.active {
        width: 60%;
    }
}

@media (max-width: 425px) {
    .drawerMenu.active {
        width: 90%;
    }
}