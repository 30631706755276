.drawerMenu {
  /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1); */
  background: white;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  opacity: 0;
  transition: width 0.5s;
  overflow: hidden;
  width: 0px;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.drawerMenu.active {
  width: 25%;
  height: 100%;
  opacity: 1;
}

.drawerMenu_logoImg {
  display: none;
  opacity: 0;
  width: 106px;
  object-fit: contain;
  transform: translateY(5px);
}

.drawerMenu_logoImg.active {
  display: flex;
  opacity: 1;
  transition: opacity 0.5s;
}

.drawerMenu_imgMenu.active {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: auto;
}

.drawerMenu_menuButton {
  cursor: pointer;
}

.drawerMenu_closingButton {
  filter: brightness(50%);
  width: 16px;
  height: 16px;
}

.drawerMenu_menuList {
  display: none;
}

.drawerMenu_menuList.active {
  display: flex;
  flex-direction: column;
}

.drawerMenu_menuListContainer {
  display: flex;
  gap: 1rem;
}

.drawerMenu_imgContainer {
  display: flex;

  & svg {
    width: 16px;
    height: 16px;
  }

  &.active-img {
    color: #4a90e2;
  }
}

.drawerMenu_titleContainer {
  white-space: nowrap;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}

.drawerMenu_link {
  padding: 1rem 1rem 1rem 0rem;
  cursor: pointer;
}

.drawerMenuBg {
  position: absolute;
  width: 100%;
  background: rgba(14, 20, 18, 0.4);
  height: 100%;
  z-index: 100;
  bottom: 0;
}

.drawerMenu_borderLeft {
  border-left: 2px solid #4a90e2;
  /* line-height: 60px; */
  border-radius: 0px 4px 4px 0px;
}
