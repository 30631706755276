.blueButton {
    color: #4A90E2;
    background: rgba(242,247,251,255);
    padding: 0.55rem 1rem;
    border-radius: 6px;
}

.filterButton {
    color: #252525;
    background: white;
    border: 1px solid #4A90E2;
    border-radius: 6px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    padding: 0.55rem 1rem;
    cursor: pointer;
}

.cancelButton {
    width: 100%;
    background: transparent;
    padding: 1rem 0rem;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

}