.container {
  display: flex;
  gap: 16px;
}

.action_btn {
  max-width: 130px;
  width: 100%;
  max-height: 36px;

  padding: 9px 12px;
  border-radius: 6px;
  background-color: #4a90e2;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
}

.action_btn:disabled {
  background-color: #b8b8b8;
  cursor: no-drop;
}
