.simpleButton_container {
    width: 100%;
    background: #4A90E2;
    padding: 1rem 0rem;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.simpleButton_container:disabled,
.simpleButton_container[disabled]{
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
}

.simpleButton_delContainer {
    width: 100%;
    background: #F8EBF0;
    padding: 0.3rem 0;
    border-radius: 2px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.simpleButton_container.small {
    max-width: 132px;
    padding: 0.3rem;
    border-radius: 2px;
}

.simpleButton_container.fit {
    width: fit-content;
    padding: 0.5rem;
}

.simpleButton_text {
    width: fit-content;
    color: white;
}

.simpleButton_text.cancel {
    color: #6B7280;
}

.simpleButtonDel {
    color: #FB0F1D;
    padding-right: 0.5rem;
}

.simpleButton_img {
    filter: invert(26%) sepia(96%) saturate(6414%) hue-rotate(349deg) brightness(107%) contrast(109%);
    width: 13px;
    height: 13px;
}

.simpleButton_variusImg {
    padding-left: 0.5rem;
}