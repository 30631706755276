.headerContainer {
  display: flex;
  justify-content: space-between;
  background: white;
  border-radius: 6px;
  align-items: center;
  padding: 1rem;
}

.headerContainer_leftPart {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.headerContainer_menuIcon {
  cursor: pointer;
}

.headerContainer_rightPart {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.headerContainer_avatarContainer {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}

.headerContainer_avatarImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.headerContainer_dropDownMenu {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.headerContainer_name {
  text-transform: capitalize;
}

.header__logo {
  width: 96px;
  height: 100%;
  object-fit: cover;
  transform: translateY(5px);
}
