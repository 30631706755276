.tableCard_container {
  min-width: 70%;
  background: white;
  padding: 1rem;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.tableCard_secondRowButtons {
  display: flex;
  gap: 0.5rem;
}

.actionButton {
  color: #0073fa;
  cursor: pointer;
}

.topHolder {
  display: flex;
  align-items: center;

  div:first-child {
    margin-right: auto;
  }

  & > span {
    max-width: 250px;
    margin-right: 16px;
  }

  .action {
    max-width: 126px;
  }
}

.filters {
  display: flex;
  align-items: center;
  gap: 0 40px;
  margin-right: 40px;
}

.dropdown {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 6px;
    left: -4px;

    width: 6px;
    height: 6px;

    background-color: #4d9ff8;
    border-radius: 100%;
  }
}

.dropdown_inner {
  padding: 8px;
}

.dropdown_text {
  font-size: 12px;
  line-height: 120%;
  color: rgb(134, 134, 134);
  padding-bottom: 6px;
  width: "100%";
}

.dropdown_input {
  max-width: 100%;

  color: #222222;
  font-weight: 500;
  font-size: 12px;

  border: 1px solid #eef3f9 !important;

  &:focus {
    outline: 1px #4d9ff8 solid !important;
  }

  &:hover {
    border: 1px solid #eef3f9;
  }

  padding: 8px 6px;
  border: 1px solid rgb(77, 159, 248);
  border-radius: 8px;
  backdrop-filter: blur(27.18px);
  background: rgb(255, 255, 255);

  &::placeholder {
    font-size: 12px;
    line-height: 14px;
  }
}

.statuses_list {
  padding: 0;
  margin: 0;

  overflow-y: scroll;
  height: 200px;
}

.statuses_list::-webkit-scrollbar {
  width: 3px;
}

.statuses_list::-webkit-scrollbar-track {
  background: #c4c4c4;
}

.statuses_list::-webkit-scrollbar-thumb {
  background-color: rgb(77, 159, 248); /* Цвет бегунка */
  border-radius: 20px; /* Округление бегунка */
}

.statuses_list_item {
  font-size: 13px;
  width: 100%;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgb(228, 228, 228);
    width: 100%;
    height: 1px;
  }
}

.statuses_btn {
  background: transparent;
  padding: 10px 8px;
  margin: 0;

  width: 100%;
  height: 100%;
  text-align: left;

  &:hover {
    background: rgba(77, 159, 248, 0.1);
  }
}

.pagination {
  display: flex;
  li {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border-radius: 6px;
  }

  li.active {
    background: #0073fa14;
  }
  .arrow {
    cursor: pointer;
    user-select: none;
  }

  .hidden {
    visibility: hidden;
  }
}

.arrow {
}
