.ant-upload-drag-container {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.textarea-review {
  height: 400px !important;
}

@media screen and (max-width: 1000px) {
  .textarea-review {
    height: auto !important;
  }
}