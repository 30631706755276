.fileCard_container {
    padding: 1rem;
    background: #EEF3F9;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fileCard_container .link {
    cursor: pointer;
}

.fileCard_actionButtonsContainer {
    display: flex;
    gap: 0.5rem;
}

.fileCard_img {
    width: 16px;
    height: 16px;
    object-fit: cover;
    cursor: pointer;
}