.p11 {
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
}

.p11_600 {
    font-weight: 600;
    font-size: 11px;
    line-height: 15px;
}

.p13_400 {
    font-weight: 400;
    font-size: 13px;
    line-height: 14px;
}

.p13 {
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
}

.p15 {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
}

.p15_600 {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
}

.p17 {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
}

.p20 {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
}

.p24 {
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
}