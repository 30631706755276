.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 450px;
}

.input {
  padding: 0.5rem;
  background: white;
  border: 1px solid #EEF3F9;
  border-radius: 6px;
}

.input.error {
  border: 1px solid red;
}

.input.error::placeholder {
  color: red;
}

.option{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.color {
  display: inline-flex;
  margin-left: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.textarea {
  width: 100%;
  height: 84px;
  margin-top: 24px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  resize: none;
  margin-bottom: 24px;
  padding: 12px 16px;

  &:hover{
    border: 1px solid #d9d9d9 !important;
  }

  &:focus{
    border: 1px solid #737373 !important;
    outline: none;
  }
}

@media screen and (max-width: 700px) {
  .container {
    width: auto;
  }
}