.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 450px;
}

.input {
  padding: 0.5rem;
  background: white;
  border: 1px solid #EEF3F9;
  border-radius: 6px;
}

.input.error {
  border: 1px solid red;
}

.input.error::placeholder {
  color: red;
}

.color {
  display: flex;
  gap: 20px;

  & > div {
    width: 100% !important;
  }
}