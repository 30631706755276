.dropdown {
  position: relative;
  max-width: 170px;

  &_trigger {
    background-color: transparent;
    border: none;
    color: rgb(37, 37, 37);
    padding-top: 0;
    font-size: 14px;

    display: flex;
    align-items: center;

    text-wrap: nowrap;
  }

  &_content {
    background-color: #fff;
    border: 1px solid rgb(228, 228, 228);
    border-radius: 8px;
    box-shadow: 0px 2px 8px 0px rgb(39 51 51 / 32%);

    position: absolute;
    top: 30px;
    left: 0;
  }

  &_img {
    width: 11px;
    height: 7px;
    cursor: pointer;
    margin-left: 6px;
  }
}
