.ant-modal-title {
  font-size: 21px !important;
  font-weight: 600 !important;
}

.item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px 10px;
  max-width: 520px;
  margin-bottom: 17px;
}

.item_key {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.item_value {
  font-size: 15px;
  line-height: 18px;
  max-width: 100%;
  width: fit-content;
  letter-spacing: 0.03em;
  color: #594747;
}
