.input {
  padding: 0.5rem;
  background: white;
  border: 1px solid #eef3f9;
  border-radius: 6px;
  margin-bottom: 20px;
}

.simpleButton_container {
  width: 100%;
  background: #4a90e2;
  padding: 1rem 0rem;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
