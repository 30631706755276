.modelCardBlock {
  width: 30%;
  background: white;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  align-self: stretch;
  padding: 60px;
}

.modelCardBlock_text {
  color: #757A80;
}

.modelCardBlock_withData {
  /* height: 100%; */
  background: white;
  border-radius: 6px 6px 0 0;
  padding: 1rem 1rem 0;
}

.modelCardMenu_container {
  display: flex;
  gap: 1rem;
}

.modelCardMenu_menu {
  cursor: pointer;
  padding-bottom: 0.5rem;
}

.modelCardMenu_menu.active {
  color: #4A90E2;

  border-bottom: 2px solid #4A90E2;
}

